const textToType = "I'm a web developer";
const typingSpeed = 100; // In milliseconds
const cursorBlinkSpeed = 500; // In milliseconds
const typewriter = document.getElementById("typewriter");
let cursor;

function createCursor() {
  cursor = document.createElement("span");
  cursor.classList.add(
    "bg-orengish",
    "animate-blink",
    "2xl:h-11",
    "2xl:w-5",
    "xl:h-10",
    "xl:w-4",
    "lg:h-7",
    "lg:w-3",
    "md:h-6",
    "md:w-2.5",
    "sm:h-6",
    "sm:w-2.5",
    "h-5",
    "w-2.5",
    "inline-block",
    "hidden"
  );
  typewriter.appendChild(cursor);
}

function typeWriterEffect(text, index) {
  if (index < text.length) {
    let typedText = document.createTextNode(text[index]);
    typewriter.insertBefore(typedText, cursor);
    setTimeout(() => typeWriterEffect(text, index + 1), typingSpeed);
  }
}

function initTypewriterEffect() {
  createCursor();
  typeWriterEffect(textToType, 0);
}

document.addEventListener("DOMContentLoaded", () => {
  setTimeout(initTypewriterEffect, 1000);
});
