window.addEventListener("DOMContentLoaded", function () {
  const menuToggle = document.getElementById("menu-toggle");
  const menuList = document.querySelector(".menu");
  const menuButtonContainer = document.querySelector(".menu-button-container");
  const menuItems = menuList.querySelectorAll("li");
  const computedStyle = getComputedStyle(menuButtonContainer);

  menuItems.forEach((menuItem) => {
    menuItem.addEventListener("click", () => {
      // Uncheck the menu toggle checkbox
      menuToggle.checked = false;
    });
  });
});
